<template>
  <div class="role-select" :class="{'role-select--error': isValid === false}">
    <ul>
      <li class="role-select--item" v-for="item in rolesData" :key="item.title">
        <div class="role-select--title" @click="toggleSelect">
          {{ item.title }}
          <IconWithTooltip :text="getGroupToolTipText(item.title)"/>
        </div>
        <div class="role-select--roles">
          <ClCheckbox
              v-for="role in item.roles"
              :key="role.title"
              :checkedProp="role.checked"
              :label="role.title"
              :valueProp="role.title"
              :id="item.title + '_' + role.title"
              @handler="checkRole"
              :toolTipText="getToolTipText(role)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {rolesDescriptions, rolesGroupsDescriptions} from "@/config/descriptions/collaboratorRoles";

export default {
  components: {
    ClCheckbox,
    IconWithTooltip,
  },
  props: {
    rolesData: {
      type: Array,
      default() {
        return []
      }
    },
    isValid: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    toggleSelect(event) {
      const item = event.target.closest('.role-select--item');
      const roles = item.querySelector('.role-select--roles');
      roles.classList.toggle('active')
    },
    checkRole(data) {
      const roleItem = data.event.target.closest('.role-select--item');
      const roleGroup = roleItem.querySelector('.role-select--title').textContent.trim();
      this.$emit('checkRole', {
        value: data.value,
        roleName: data.event.target.value,
        roleGroup: roleGroup,
      });
    },
    getToolTipText(role) {
      return rolesDescriptions[role] ? rolesDescriptions[role] : '';
    },
    getGroupToolTipText(title) {
      return rolesGroupsDescriptions[title] ? rolesGroupsDescriptions[title] : '';
    },
  },
}
</script>

<style lang="scss">
  .role-select {
    .role-select--title {
      position: relative;
      padding-left: 20px;
      width: fit-content;
      padding-right: 10px;
      cursor: pointer;
      &::before {
        content: '+';
        font-size: 22px;
        line-height: 1;
        position: absolute;
        left: 0;
        top: -2px;
      }
      .fa-question-circle-o {
        top: 4px;
      }
    }
    &--roles {
      display: none;
      &.active {
        display: block;
      }
    }
    &--error {
      border: solid 1px #d14334;
      border-radius: 12px;
      padding: 10px;
      &:before {
        content: 'Required';
        display: block;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        color: #d14334;
        position: absolute;
        bottom: -14px;
        left: 0;
      }
    }
    .zq--checkbox--wrap {
      margin-left: 20px;
      padding: 5px 0;
      width: fit-content;
      .toggle__label {
        padding-right: 10px;
      }
      .fa-question-circle-o {
        top: 2px;
      }
    }
  }
</style>
